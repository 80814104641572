export const Image = ({width = '100%', height = '100%', size = 'cover', src, ...props}) => (
  <div
    {...props}
    css={{
      width,
      height,
      backgroundImage: `url(${src})`,
      backgroundSize: size,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    }}
  />
)

export default Image
