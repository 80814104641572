import {useState, useMemo, useCallback, useRef, useEffect} from 'react'
import {useSnackbar} from 'notistack'
import {modes} from '../common/constants'

export const useSteps = (slug, mode) => {
  const {enqueueSnackbar, closeSnackbar} = useSnackbar()

  const [step, setStepOrigin] = useState(0)
  const [length, setStepLength] = useState(0)

  // 使用广播接口实现联动播放，任意页面切换，其他的都同步切换
  // 这个并不是在一个局域网，而仅仅是一个浏览器的多个 tab
  const [inited, setInited] = useState(false)
  const politicsChannel = useRef(null)
  const messageHandler = useCallback(
    e => {
      if (inited && step !== e.data.step) setStep(e.data.step)
    },
    [step, inited],
  )
  useEffect(() => {
    politicsChannel.current = new BroadcastChannel(slug)
    setInited(true)
  }, [])
  useEffect(() => {
    if (politicsChannel.current) politicsChannel.current.onmessage = messageHandler
  }, [messageHandler])

  const setStep = useCallback(
    ind => {
      setStepOrigin(ind)
      if (politicsChannel.current && mode == modes.presenter)
        politicsChannel.current.postMessage({step: ind})
    },
    [mode],
  )

  const next = useCallback(() => {
    closeSnackbar()
    if (step === length - 1) {
      enqueueSnackbar('已经是最后一页了')
    } else if (step > length - 1) {
      setStep(length - 1)
    } else {
      setStep(step + 1)
    }
  }, [step, length])

  const previous = useCallback(() => {
    closeSnackbar()
    if (step === 0) {
      enqueueSnackbar('已经是第一页了')
    } else if (step < 0) {
      setStep(0)
    } else {
      setStep(step - 1)
    }
  }, [step])

  const operates = useMemo(() => {
    return {
      next,
      previous,
      step,
      setStep,
    }
  }, [next, previous, step])

  return [step, setStep, setStepLength, operates]
}

export default useSteps
