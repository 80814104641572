import React from 'react'
import {graphql} from 'gatsby'
import {css} from '@emotion/core'
import {MDXRenderer} from 'gatsby-plugin-mdx'
import {MDXProvider} from '@mdx-js/react'
import useSplitSlides from '../components/slide/common/split-slides'
import {SnackbarProvider} from 'notistack'

import mdxComponents from '../components/slide/mdx'

import Deck from '../components/slide/deck'

let store = {
  notes: {},
}
export const NotesContext = React.createContext()
const {Provider, Consumer} = NotesContext

const wrapper = ({children, title, slug, fields}) => {
  const slides = useSplitSlides(children)
  return <Deck title={title} slug={slug} slides={slides} fields={fields} />
}

const components = {
  wrapper,
  ...mdxComponents,
}

export const SlidePlain = React.memo(props => {
  const {title, slug, body, fields} = props
  return (
    <SnackbarProvider>
      <Provider value={store}>
        <MDXProvider components={components}>
          <MDXRenderer title={title} slug={slug} fields={fields} children={body} />
        </MDXProvider>
      </Provider>
    </SnackbarProvider>
  )
})

const Slide = React.memo(
  ({data, ...props}) => {
    return (
      <SlidePlain
        title={props.pageContext.title}
        slug={props.pageContext.slug}
        body={data.mdx.body}
        fields={data.mdx.fields}
      />
    )
  },
  (prev, next) => {
    return (
      prev.data.mdx.body === next.data.mdx.body && prev.pageContext.title === next.pageContext.title
    )
  },
)

export default Slide

export const pageQuery = graphql`
  query($id: String!) {
    site {
      siteMetadata {
        keywords
      }
    }
    mdx(fields: {id: {eq: $id}}) {
      id
      body
      fields {
        theme
        title
      }
    }
  }
`
