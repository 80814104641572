import React from 'react'

export const SplitRight = ({children, ...props}) => {
  const [first, ...rest] = React.Children.toArray(children)
  return (
    <div
      {...props}
      css={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
      }}
    >
      <div css={{width: '50%'}}>{rest}</div>
      <div css={{width: '50%'}}>{first}</div>
    </div>
  )
}

export default SplitRight
