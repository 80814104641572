import React, {useEffect, useState} from 'react'

var hhmmss = function(inp) {
  var sec = parseInt(inp)
  var mins = parseInt(sec / 60)
  var f_x = parseInt(inp * 100) - sec * 100
  sec = sec - mins * 60
  mins = mins < 10 ? '0' + mins : mins
  sec = sec < 10 ? '0' + sec : sec
  var string = mins + ':' + sec
  return string
}

let ticker

export const Timer = props => {
  const [sec, setSec] = useState(0)
  const [startAt, setStartAt] = useState(0)
  const [timer, setTimer] = useState(false)

  useEffect(() => {
    const tick = () => {
      if (!timer) return
      setSec(sec + 1)
    }
    ticker = setInterval(tick, 1000)
    return () => {
      clearInterval(ticker)
    }
  }, [timer, sec])

  const toggle = () => {
    setTimer(!timer)
    setStartAt(Date.now())
  }

  const reset = () => {
    setStartAt(Date.now())
  }

  return (
    <React.Fragment>
      <button
        onClick={reset}
        disabled={!sec}
        title="Reset timer"
        css={{
          margin: '0 1px',
        }}
      >
        Reset
      </button>{' '}
      <button
        title={timer ? 'Stop timer' : 'Start timer'}
        onClick={toggle}
        css={{
          margin: '0 1px',
        }}
      >
        {timer ? 'Stop' : 'Start'}
      </button>{' '}
      {hhmmss(!timer ? 0 : (Date.now() - startAt) / 1000)}
    </React.Fragment>
  )
}

export default Timer
