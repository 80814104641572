import React, {useContext} from 'react'
import {css} from '@emotion/core'
import Zoom from '../components/zoom'
import Slide from '../components/slide'
import Footer from '../components/presenter-footer'
import {NotesContext} from '../../../templates/slide'

export const Presenter = React.memo(({slides, step, setMode, themeName, operates, children}) => {
  const value = useContext(NotesContext)

  return (
    <div
      css={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        color: 'white',
        background: '#4e4e4e',
      }}
    >
      <div
        css={{
          display: 'flex',
          flex: '1 1 auto',
          height: '100vh',
        }}
      >
        <div
          css={{
            width: '75%',
            padding: '16px 0 16px 16px',
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
          }}
        >
          <Zoom zoom={3 / 4} active={true}>
            {children}
          </Zoom>
          <div
            css={{
              height: 96,
              padding: '5px 0',
              fontSize: '1.5em',
            }}
            css={css`
              p {
                margin: 5px 0;
              }
            `}
          >
            {value && value.notes && value.notes[step] && value.notes[step]}
          </div>
        </div>
        <div
          css={{
            width: '25%',
            padding: 3,
            overflow: 'auto',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div
            css={{
              height: 96,
              display: 'flex',
              fontSize: 1,
              fontWeight: 'bold',
              fontVariantNumeric: 'tabular-nums',
              justifyContent: 'space-between',
            }}
          >
            <Footer step={step} length={slides.length} themeName={themeName} />
          </div>
          <div
            css={{
              overflow: 'auto',
              flex: 1,
            }}
          >
            {slides.map((slide, ind) => (
              <div
                key={ind}
                css={css`
                  margin-bottom: 4px;
                `}
              >
                <Zoom
                  key={ind}
                  active={ind === step}
                  ratio={4 / 3}
                  zoom={1 / 4}
                  onClick={() => operates.setStep(ind)}
                >
                  <Slide index={step} slide={slide} preview setMode={setMode} operates={operates} />
                </Zoom>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
})

export default Presenter
