import React, {useEffect, useRef} from 'react'
import Zoom from './zoom'
import Slide from './slide'

const noop = () => {}

export const SlideList = ({
  slides = [],
  ratio = 16 / 9,
  zoom = 1 / 4,
  onClick = noop,
  operates,
  setMode,
  step,
  ...props
}) => {
  const thumb = useRef(null)

  useEffect(() => {
    const el = thumb.current
    if (!el) return
    if (typeof el.scrollIntoViewIfNeeded === 'function') {
      el.scrollIntoViewIfNeeded()
    }
  })

  return (
    <React.Fragment>
      {slides.map((slide, i) => (
        <div
          {...props}
          key={i}
          role="link"
          ref={i === step ? thumb : null}
          style={
            step === i
              ? {
                  position: 'relative',
                  zIndex: 1,
                }
              : null
          }
        >
          <Zoom zoom={zoom} current={step === i} ratio={ratio} active onClick={() => onClick(i)}>
            <Slide slide={slide} index={i} preview setMode={setMode} operates={operates} />
          </Zoom>
        </div>
      ))}
    </React.Fragment>
  )
}

export default SlideList
