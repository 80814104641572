import React, {Fragment} from 'react'
import {css} from '@emotion/core'

import useSwipe from '../hooks/use-swipe'

export const Slide = React.memo(({slide, index, preview, setMode, operates}) => {
  const swipeProps = useSwipe(operates, setMode)

  return (
    <div
      {...(!preview ? swipeProps : {})}
      css={theme =>
        css({
          boxSizing: 'border-box',
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflow: 'hidden',
          position: 'relative',
          color: theme.colors.text,
          background: theme.colors.background,
          padding: '1em',
          ...theme.styles,
        })
      }
      style={
        {
          // display: index === operates.step ? 'flex' : 'none',
        }
      }
    >
      {slide}
    </div>
  )
})

export default Slide
