/* eslint-disable */
import {useEffect} from 'react'
import {modes} from '../common/constants'

const keys = {
  right: 39,
  left: 37,
  up: 38,
  down: 40,
  space: 32,
  p: 80,
  o: 79,
  g: 71,
  esc: 27,
  pageUp: 33,
  pageDown: 34,
  enter: 13,
}

const inputElements = ['input', 'select', 'textarea', 'a', 'button']

export const useKeyboard = (setMode, operates) => {
  useEffect(() => {
    const handleKeyDown = e => {
      const {metaKey, ctrlKey, shiftKey, altKey} = e
      if (metaKey || ctrlKey) return

      // ignore custom keyboard shortcuts when elements are focused
      const el = document.activeElement.tagName.toLowerCase()
      if (inputElements.includes(el)) return

      if (shiftKey) {
        switch (e.keyCode) {
          case keys.space:
            operates.previous()
            break
        }
      } else if (altKey) {
        switch (e.keyCode) {
          case keys.p:
            setMode(modes.presenter)
            break
          case keys.g:
            setMode(modes.grid)
            break
        }
      } else {
        switch (e.keyCode) {
          case keys.right:
          case keys.down:
          case keys.pageDown:
          case keys.space:
            operates.next()
            break
          case keys.left:
          case keys.up:
          case keys.pageUp:
            operates.previous()
            break
          case keys.esc:
          case keys.enter:
            setMode(modes.normal)
            break
        }
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [setMode, operates])
}

export default useKeyboard
