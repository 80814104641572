import React, {Fragment, useState, useEffect} from 'react'
import {modes} from '../common/constants'
import {css} from '@emotion/core'

const DefaultProvider = props => React.createElement(Fragment, null, props.children)

export default React.memo(props => {
  const {mode, theme} = props

  const {Provider = DefaultProvider} = theme

  return (
    <Provider>
      <div
        {...props}
        css={css`
          width: 100vw;
          height: 100vh;
          overflow: hidden;
          *: {
            box-sizing: border-box;
          }
        `}
      />
    </Provider>
  )
})
