import React, {useRef, useEffect, useState} from 'react'
import {css} from '@emotion/core'
import {useSprings, animated} from 'react-spring'

export const KViewPager = props => {
  // : KViewPagerProps
  let {pages = [], style = {}, scrollDown = false, step = 0} = props

  const [props_, set] = useSprings(pages.length, i => ({
    offset: (i - step) * 100,
    sc: 1 - Math.abs(i - step) / pages.length,
  })) // <{ offset: number; sc: number; display: string; }>

  useEffect(() => {
    set(i => ({offset: (i - step) * 100, sc: 1 - Math.abs(i - step) / pages.length}))
  }, [scrollDown, step])

  return (
    <div
      css={css`
        overflow: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        .k-viewPager-box {
          position: absolute;
          left: 0;
          top: 0;
          will-change: transform;
        }

        .k-viewPager-wrap {
          width: 100%;
          height: 100%;
          will-change: transform;
          box-shadow: 0 62.5px 125px -25px rgba(50, 50, 73, 0.5),
            0 37.5px 75px -37.5px rgba(0, 0, 0, 0.6);
        }

        .k-viewPager,
        .k-viewPager-box {
          width: 100%;
          height: 100%;
        }
      `}
      style={style}
    >
      {props_.map(({offset, sc}, i) => (
        <animated.div
          key={i}
          className="k-viewPager-box"
          style={{
            transform: offset.interpolate(x => `translate${scrollDown ? 'Y' : 'X'}(${x}%)`),
          }}
        >
          <animated.div
            className="k-viewPager-wrap"
            style={{
              transform: sc.interpolate(s => `scale(${s})`),
            }}
          >
            {pages[i]}
          </animated.div>
        </animated.div>
      ))}
    </div>
  )
}

export default KViewPager
