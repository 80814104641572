import {css} from '@emotion/core'
import React, {useState, useEffect} from 'react'
import Clock from './clock'
import Timer from './timer'
import {modes} from '../common/constants'

export default ({step, length, themeName}) => {
  const [href, setHref] = useState('')

  useEffect(() => {
    setHref(`${location.origin}${location.pathname}#${step}-${modes.normal}-${themeName}`)
  }, [step])

  return (
    <div
      css={css`
        width: 100%;
      `}
    >
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        `}
      >
        <div>
          {step + 1} / {length}
        </div>
        <div
          css={{
            margin: '0 4px',
          }}
        >
          <a
            href={href}
            rel="noopener noreferrer"
            target="_blank"
            css={{
              color: 'inherit',
            }}
          >
            打开新窗口同步放映 ↗︎
          </a>
        </div>
      </div>
      <div css={{margin: '0 auto'}} />
      <div
        css={{
          display: 'flex',
          alignItems: 'center',
          margin: '0 4px 10px',
          justifyContent: 'flex-end',
        }}
      >
        <Timer />
      </div>
      <div
        css={css`
          margin-bottom: 10px;
          text-align: right;
        `}
      >
        <Clock />
      </div>
    </div>
  )
}
