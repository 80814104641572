import { modes } from '../common/constants'
import SlideList from '../components/slide-list'

export default ({ slides, step, setMode, operates }) => {
  return (
    <div
      css={{
        minHeight: '100vh',
        color: 'white',
        background: 'black',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        css={{
          width: '100vw',
          display: 'flex',
          flexWrap: 'wrap',
        }}
      >
        <SlideList
          slides={slides}
          step={step}
          onClick={i => {
            operates.setStep(i)
            setMode(modes.normal)
          }}
          css={{
            width: '25%',
            margin: 0,
            padding: '3px',
            boxSizing: 'border-box',
          }}
        />
      </div>
    </div>
  )
}
