import React from 'react'

export const ChatBoxItem = ({side, width = '20em', children}) => {
  return (
    <div style={{width: width, textAlign: side, marginBottom: '1em'}}>
      <span
        style={{
          background: side == 'left' ? '#2196F3' : '#2f9a41',
          color: '#fff',
          padding: '.4em',
          fontSize: '.6em',
          borderRadius: '1em',
          [`borderBottom${side.replace(/^(\w)/, side[0].toUpperCase())}Radius`]: 0,
          display: 'inline-block',
          maxWidth: '16em',
          textAlign: 'left',
        }}
      >
        {children}
      </span>
    </div>
  )
}

export default ChatBoxItem
