import React from 'react'
import {css} from '@emotion/core'

export const Horizontal = ({...props}) => {
  const children = React.Children.toArray(props.children)

  return (
    <div
      {...props}
      css={{
        display: 'flex',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
      }}
    >
      {children.map((child, i) => (
        <div key={child.key} css={{width: 100 / children.length + '%'}}>
          {child}
        </div>
      ))}
    </div>
  )
}

export default Horizontal
