import React from 'react'
import Appear from './appear'
import Notes from './notes'
import Head from './head'
import Image from './image'
import FullScreenCode from './full-screen-code'
import Horizontal from './horizontal'
import Invert from './invert'
import Split from './split'
import SplitRight from './split-right'
import ChatBoxItem from './chat-box-item'

import SyntaxHighlighter from 'react-syntax-highlighter'

export const getLanguage = className => {
  const match = /language-(\w*)/.exec(className || 'language-javascript')
  let lang = 'javascript'
  if (match && match.length > 1) {
    lang = match[1]
  }
  return lang
}

export const pre = props => props.children

export const code = props => {
  const language = getLanguage(props.className)
  return <SyntaxHighlighter language={language} {...props} />
}

export default {
  Appear,
  Notes,
  Head,
  Image,
  FullScreenCode,
  Horizontal,
  Invert,
  Split,
  SplitRight,
  ChatBoxItem,
  pre,
  code,
}
