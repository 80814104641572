import {useEffect, useContext, useRef} from 'react'
import {NotesContext} from '../../../templates/slide'

export const Notes = props => {
  const value = useContext(NotesContext)
  const spanRef = useRef(null)

  useEffect(() => {
    let wrapperNode = spanRef.current
    let slideNode = spanRef.current
    let stoper = 0
    while (!/KViewPager/.test(wrapperNode.className) && wrapperNode.parentElement) {
      wrapperNode = wrapperNode.parentElement
      if (/k-viewPager-box/.test(wrapperNode.className)) {
        slideNode = wrapperNode
      }
      if (stoper++ > 100) break
    }

    if (wrapperNode.tagName.toLowerCase() !== 'html') {
      const index = [...wrapperNode.children].indexOf(slideNode)
      if (value && value.notes) value.notes[index] = props.children
    }
  }, [props.children])

  return <span ref={spanRef}></span>
}

export default Notes
