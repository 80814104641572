import {css} from '@emotion/core'

export const Invert = ({...props}) => (
  <div
    {...props}
    css={theme => ({
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      height: '100%',
      color: theme.colors.background,
      background: theme.colors.text,
      a: {
        color: 'inherit',
      },
    })}
  />
)

export default Invert
