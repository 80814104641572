import React from 'react'

export const Split = ({ children, ...props }) => {
  const eles = React.Children.toArray(children)
  return (
    <div
      {...props}
      css={{
        display: 'flex',
        alignItems: 'center',
        // height: '100%',
        textAlign: 'center',
      }}
    >
      {eles.map(ele => (
        <div css={{ flex: 1 }}>{ele}</div>
      ))}
    </div>
  )
}

export default Split
