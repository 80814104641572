import {css} from '@emotion/core'

export const Zoom = ({
  ratio,
  current = false,
  active = false,
  onClick,
  href,
  zoom = 1,
  ...props
}) => (
  <div
    css={{
      boxSizing: 'border-box',
      // border: '1px solid',
      width: '100%',
      position: 'relative',
      height: ratio ? 0 : '100%',
      paddingBottom: ratio ? `${(1 / ratio) * 100}%` : 0,
      overflow: 'hidden',
    }}
  >
    <div
      {...props}
      css={{
        boxSizing: 'border-box',
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: `${(1 / zoom) * 100}%`,
        height: `${(1 / zoom) * 100}%`,
        transformOrigin: '0 0',
        transform: `scale(${zoom})`,
        opacity: active ? 1 : 0.4,
      }}
    />
    {href ? (
      <a
        css={css`
          position: absolute;
          display: block;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;
        `}
        style={{
          outline: current ? 'solid 3px cyan' : 'none',
        }}
        target="_blank"
        href={href}
      ></a>
    ) : (
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          z-index: 1;
        `}
        style={{
          outline: current ? 'solid 3px cyan' : 'none',
        }}
        onClick={onClick}
      ></div>
    )}
  </div>
)

export default Zoom
