const blue = '#00cdf1'
const black = '#003d48'
const secondary = '#f0f'
const primary = '#0800e3'

export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
  colors: {
    text: black,
    background: blue,
    primary,
  },
  styles: {
    fontSize: '16px',
    '@media screen and (min-width:736px)': {
      fontSize: '18px',
    },
    '@media screen and (min-width:900px)': {
      fontSize: '24px',
    },
    '@media screen and (min-width:1024px)': {
      fontSize: '30px',
    },
    h1: {
      fontSize: '2em',
      fontWeight: '100',
      lineHeight: 1.125,
      marginTop: 0,
    },
    fontFamily: '"Source Code Pro", monospace',
    pre: {
      color: blue,
      background: black,
    },
    code: {
      color: secondary,
    },
  },
}
