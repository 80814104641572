import React from 'react'
import { graphql } from 'gatsby'
import { css } from '@emotion/core'
import { ThemeProvider } from 'emotion-theming'
import { MDXProvider } from '@mdx-js/react'
import { MDXRenderer } from 'gatsby-plugin-mdx'
import SEO from '../components/seo'
import Layout from '../components/layout'
import theme from '../../config/theme'
import Container from '../components/container'
import useSplitSlides from '../components/slide/common/split-slides'
import Zoom from '../components/slide/components/zoom'
import Slide from '../components/slide/components/slide'
import coverTheme from '../components/slide/theme/cover'
import HeaderImage from '../images/studio-hero.svg'
import Hero from '../components/big-hero'
import * as themes from '../components/slide/theme/index'

import mdxComponents from '../components/slide/mdx'

export default function Index({ data: { decks } }) {
  return (
    <Layout
      headerColor={theme.colors.white}
      hero={
        <Hero
          title="👨‍💻 演示 / Slides"
          text="推动组内分享，分享工作方法、技术调研、优雅的解决方案和思路。能够头脑风暴一个技术点、深入一个有意思的主题，技术人员的一个表达出口。"
          bigEmoji="👨‍💻"
          noImage={true}
        />
      }
      headerColor={theme.colors.white}
    >
      <SEO />
      <Container>
        <div
          css={css`
            display: grid;
            grid-gap: 20px;
            grid-template-columns: repeat(auto-fill, minmax(302px, 1fr));
          `}
        >
          {decks.edges.map(({ node }) => {
            const wrapper = ({ children }) => {
              const slides = useSplitSlides(children)
              return (
                <Zoom
                  zoom={1 / 2}
                  ratio={16 / 9}
                  active
                  href={`/${node.fields.slug.replace(/^\//, '')}`}
                >
                  <Slide slide={slides[0]} preview />
                </Zoom>
              )
            }

            const components = {
              wrapper,
              ...mdxComponents,
            }

            const theme = themes[node.fields.theme] || themes['dark']

            return (
              <ThemeProvider key={node.fields.slug} theme={theme}>
                <div
                  css={css`
                    padding: 3px;
                  `}
                  title={node.frontmatter.date}
                >
                  <MDXProvider components={components}>
                    <MDXRenderer
                      title={node.frontmatter.title}
                      slug={node.fields.slug}
                      children={node.body}
                    />
                  </MDXProvider>
                </div>
              </ThemeProvider>
            )
          })}
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    decks: allMdx(
      filter: {fields: {isSlide: {eq: true}}}
      sort: {order: DESC, fields: [frontmatter___date]}
    ) {
      edges {
        node {
          id
          body
          frontmatter {
            title
            date
            description
            tech
            slug
          }
          fields {
            slug
            theme
          }
        }
      }
      totalCount
    }
  }
`
