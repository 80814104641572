import {css} from '@emotion/core'

export const FullScreenCode = ({...props}) => (
  <div
    {...props}
    css={css`
      width: 100%;
      height: 100%;
      pre: {
        margin: 0 !important;
        width: 100%;
        height: 100%;
        overflow: auto;
      },
    `}
  />
)

export default FullScreenCode
