export const modes = {
  normal: 'normal',
  presenter: 'presenter',
  grid: 'grid',
}
export const modeNames = {
  normal: '普通模式 - 正常全页放映',
  presenter: '演示模式 - 同步播放、多页预览、查看备注',
  grid: '网格 - 缩放展示、快速定位',
}
