const primary = '#07c'
const secondary = '#80c'
const muted = '#f6f6ff'

export default {
  colors: {
    text: '#000',
    background: '#fff',
    primary,
    secondary,
    muted,
  },
  styles: {
    fontFamily: 'system-ui, sans-serif',
    fontSize: '16px',
    textAlign: 'center',
    '@media screen and (min-width:736px)': {
      fontSize: '22px',
    },
    '@media screen and (min-width:900px)': {
      fontSize: '28px',
    },
    '@media screen and (min-width:1024px)': {
      fontSize: '36px',
    },
    p: {
      fontSize: '1em',
      margin: '0 0 0.8em 0',
    },
    h1: {
      fontSize: '2em',
      fontWeight: 'bold',
      lineHeight: 1.125,
      marginTop: 0,
    },
    h2: {
      fontSize: '1.6em',
      fontWeight: 'bold',
      lineHeight: 1.125,
      marginTop: 0,
    },
    h3: {
      fontSize: '1.3em',
      fontWeight: 'bold',
      lineHeight: 1.125,
      marginTop: 0,
    },
    h4: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      lineHeight: 1.125,
      marginTop: 0,
    },
    h5: {
      fontSize: '1.1em',
      fontWeight: 'bold',
      lineHeight: 1.125,
      marginTop: 0,
    },
    h6: {
      fontSize: '1em',
      fontWeight: 'bold',
      lineHeight: 1.125,
      marginTop: 0,
    },
    a: {
      color: primary,
    },
    ul: {
      margin: 0,
      textAlign: 'left',
    },
    ol: {
      margin: 0,
      textAlign: 'left',
    },
    inlineCode: {
      fontFamily: '"Roboto Mono", Menlo, monospace',
    },
    code: {
      fontFamily: '"Roboto Mono", Menlo, monospace',
    },
    pre: {
      fontFamily: '"Roboto Mono", Menlo, monospace',
      padding: 3,
      textAlign: 'left',
    },
    img: {
      maxWidth: '100%',
      height: 'auto',
      objectFit: 'cover',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      paddingRight: '.5em',
      paddingTop: '.25em',
      paddingBottom: '.25em',
      borderBottom: '1px solid',
      verticalAlign: 'top',
    },
    td: {
      textAlign: 'left',
      paddingRight: '.5em',
      paddingTop: '.25em',
      paddingBottom: '.25em',
      borderBottom: '1px solid',
      verticalAlign: 'top',
    },
    blockquote: {
      fontWeight: 'bold',
    },
  },
}
