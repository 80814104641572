const primary = '#08f'
const secondary = '#7890a2'
const darkgray = '#333'

export default {
  colors: {
    text: '#fff',
    background: '#000',
    primary,
    secondary,
    darkgray,
  },
  styles: {
    fontSize: '16px',
    '@media screen and (min-width:736px)': {
      fontSize: '18px',
    },
    '@media screen and (min-width:900px)': {
      fontSize: '24px',
    },
    pre: {
      color: `${secondary} !important`,
      background: `${darkgray}  !important`,
    },
    code: {
      color: `${secondary} !important`,
    },
  },
}
