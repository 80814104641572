import {useSwipeable} from 'react-swipeable'
import {modes} from '../common/constants'

export const useSwipe = (operates, setMode) => {
  const onSwipedLeft = e => {
    operates.next()
  }

  const onSwipedRight = e => {
    operates.previous()
  }

  const onSwipedUp = e => {
    setMode(modes.presenter)
  }

  const onSwipedDown = e => {
    setMode(modes.normal)
  }

  const props = useSwipeable({
    onSwipedLeft,
    onSwipedRight,
    onSwipedUp,
    onSwipedDown,
  })

  return props
}

export default useSwipe
