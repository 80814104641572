import React, { useReducer, useCallback, useState, useEffect } from 'react'
import { Global, css } from '@emotion/core'
import { ThemeProvider, withTheme } from 'emotion-theming'
import { Helmet } from 'react-helmet'
import merge from 'lodash.merge'
import Drawer from '@material-ui/core/Drawer'
import useKeyboard from './hooks/use-keyboard'
import useSteps from './hooks/use-steps'
import Wrapper from './components/wrapper'
import Slide from './components/slide'
import { modes, modeNames } from './common/constants'
import Fab from '@material-ui/core/Fab'
import VideogameAssetIcon from '@material-ui/icons/VideogameAsset'
import * as themes from './theme/index'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Divider from '@material-ui/core/Divider'
import ListSubheader from '@material-ui/core/ListSubheader'

import KViewPager from './components/view-pager'

import Presenter from './modes/presenter'
import Grid from './modes/grid'

const GoogleFont = ({ theme }) => {
  if (!theme.googleFont) return false
  return (
    <Helmet>
      <link rel="stylesheet" href={theme.googleFont} />
    </Helmet>
  )
}

const mergeThemes = (...themes) =>
  themes.reduce((acc, theme) => (typeof theme === 'function' ? theme(acc) : merge(acc, theme)), {})

const DefaultMode = ({ children }) => <React.Fragment children={children} />

export default React.memo(({ slides = [], title = '', slug = '', fields }) => {
  const [hashInited, setHashInited] = useState(false)
  const [controllerVisible, setControllerVisible] = useState(false)
  const [components_, setComponents] = useState(null)
  const [theme, setTheme] = useState({})
  const [themeName, setThemeName] = useState('default')
  const [mode, setMode] = useState(modes.normal)
  const [step, setStep, setStepLength, operates] = useSteps(slug, mode)

  useEffect(() => {
    setStepLength(slides.length)
  }, [slides])

  const updateTheme = useCallback((themeName = 'default') => {
    const { components, ...mergedTheme } = mergeThemes(themes.default, themes[themeName])
    setComponents(components)
    setTheme(mergedTheme)
    setThemeName(themeName)
  }, [])

  useEffect(() => {
    if (hashInited) location.hash = `${step}-${mode}-${themeName}`
  }, [mode, step, themeName, hashInited])

  const detectFromHash = useCallback(() => {
    const hash = location.hash.replace(/^#/, '')
    if (hash !== '') {
      let ary = hash.split('-')

      let stepNow = parseInt(ary[0])
      if (!isNaN(stepNow) && stepNow !== step) setStep(stepNow)

      let modeNow = ary[1]
      if (modeNow && modeNow !== '' && modeNow !== mode && modes[modeNow]) setMode(modeNow)

      let themeNow = ary[2]
      if (themeNow && themeNow !== '' && themeNow !== themeName && themes[themeNow]) {
        updateTheme(themeNow)
      }
    }
    setHashInited(true)
  }, [step, mode, themeName])

  useEffect(() => {
    window.addEventListener('hashchange', detectFromHash)
    return () => {
      window.removeEventListener('hashchange', detectFromHash)
    }
  }, [detectFromHash])

  useEffect(() => {
    updateTheme(fields.theme || 'default')
    detectFromHash()
  }, [])

  let Mode = DefaultMode

  switch (mode) {
    case modes.presenter:
      Mode = Presenter
      break
    case modes.grid:
      Mode = Grid
      break
    default:
      break
  }

  useKeyboard(setMode, operates)

  return (
    <>
      <Helmet>{title && <title>{title}</title>}</Helmet>
      <GoogleFont theme={theme} />

      <ThemeProvider components={components_} theme={theme}>
        <Global
          styles={{
            body: {
              overflow: mode === modes.normal ? 'hidden' : null,
              padding: 0,
              margin: 0,
            },
          }}
        />
        <Wrapper mode={mode} step={step} theme={theme}>
          <Mode
            slides={slides}
            step={step}
            setMode={setMode}
            operates={operates}
            themeName={themeName}
          >
            <KViewPager
              scrollDown={false}
              step={step}
              pages={slides.map((slide, i) => (
                <Slide index={i} key={i} setMode={setMode} operates={operates} slide={slide} />
              ))}
              onIndexChange={index => { }}
            />
            <div
              css={css`
                position: absolute;
                top: 5px;
                right: 5px;
                opacity: 0.3;
              `}
            >
              {step + 1}/{slides.length}
            </div>
          </Mode>
        </Wrapper>
      </ThemeProvider>

      <Fab
        size="small"
        css={{
          position: 'absolute !important',
          bottom: '2em',
          right: '2em',
          zIndex: 2,
        }}
        onClick={() => setControllerVisible(true)}
      >
        <VideogameAssetIcon />
      </Fab>
      <Drawer anchor="right" open={controllerVisible} onClose={() => setControllerVisible(false)}>
        <div
          css={css`
            width: 300px;
            padding: 10px;
          `}
        >
          <List component="nav" subheader={<ListSubheader>主题</ListSubheader>}>
            {Object.keys(themes).map(option => (
              <ListItem
                key={option}
                button
                selected={option === themeName}
                onClick={() => {
                  setControllerVisible(false)
                  updateTheme(option)
                }}
              >
                <ListItemText primary={option} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <List component="nav" subheader={<ListSubheader>模式</ListSubheader>}>
            {Object.keys(modes).map(option => (
              <ListItem
                key={option}
                button
                selected={option === mode}
                onClick={() => {
                  setControllerVisible(false)
                  setMode(option)
                }}
              >
                <ListItemText primary={modeNames[option]} />
              </ListItem>
            ))}
          </List>
          <Divider />
          <div
            css={css`
              padding: 20px 10px;
              text-align: center;
            `}
          >
            Prowered By{' '}
            <a target="_blank" href="https://ubug.io/">
              Ubug
            </a>
          </div>
        </div>
      </Drawer>
    </>
  )
})
