const primary = '#42ff71'
const secondary = '#f0f'
const darkgray = '#333'

export default {
  googleFont: 'https://fonts.googleapis.com/css?family=Source+Code+Pro',
  colors: {
    text: '#42ff71',
    background: '#000',
    primary,
    secondary,
    darkgray,
  },
  styles: {
    fontSize: '16px',
    '@media screen and (min-width:736px)': {
      fontSize: '18px',
    },
    '@media screen and (min-width:900px)': {
      fontSize: '24px',
    },
    '@media screen and (min-width:1024px)': {
      fontSize: '30px',
    },
    pre: {
      color: `${secondary} !important`,
      background: `${darkgray}  !important`,
    },
    code: {
      color: `${secondary} !important`,
    },
  },
}
